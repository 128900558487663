import React, { useState } from 'react';
import moment from "moment";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { DashCard, DashPopover, DashTable } from "../components/components";

const styles = {
   value: {
      fontSize: '5.5rem',
		color: '#444444'
   },
   valueDetail: {
      fontSize: '3.5rem',
		color: '#d32f2f'
   }
}
export const TarefasCard = withStyles(styles)((props) => {

   const [anchorEl, setAnchorEl] = useState(null)

   const id = (props && props.id) || "tarefas";
   const indicadores = (props && props.indicadores) ||  { pendentes: 0, atrasadas: 0};
   const tarefas = (props && props.tarefas) ||  { atrasadas: [], pendentes: [] };
   const classes = (props && props.classes) || {};

   const columns = [
      {
         identifier: "tarefa_id",
         label: "ID",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "cliente",
         label: "Cliente",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "tarefa_titulo",
         label: "Título",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "recurso_nome",
         label: "Recurso",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "sla",
         label: "SLA",
         variant: row => handleColumnVariant(row)
      },
      {
         identifier: "data_fim_prevista",
         label: "Data Fim",
         variant: row => handleColumnVariant(row),
         format: value => (value && moment(value).format("DD/MM/YYYY")) || "---"
      }
   ];

   function handleColumnVariant(row) {
      if (row.executando) {
         return "info";
      }else if (row.atrasado || row.sla_vencido === 1) {
         return "danger";
      }else if (row.sla_vencido === 2){  // à vencer
         return "warning";
      }else if (row.finalizado) {
         return "success";
      }else {
         return "default";
      }
   }

   return (
      <React.Fragment>
         <DashCard id={id} onClick={event => setAnchorEl(event.target)} title={"TAREFAS"} details={indicadores.atrasadas > 0 ? indicadores.atrasadas : ""} variant={indicadores.atrasadas > 0 ? 'danger' : 'primary'}>
            <Typography variant="h1" color="textPrimary" align="center" className={classes.value}>
               {indicadores.pendentes > 0 || indicadores.atrasadas > 0 ? indicadores.pendentes + indicadores.atrasadas : '0'}
            </Typography>
         </DashCard>
         <DashPopover anchor={anchorEl} onClose={_ => setAnchorEl(null)} title={"Tarefas"}>
            <DashTable columns={columns} rows={tarefas.atrasadas.concat(tarefas.pendentes)} emptyMessage="Nenhuma tarefa disponível" />
         </DashPopover>
      </React.Fragment>
   )
});

export default TarefasCard;