import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";

import { DashCard } from "../components/components";

const styles = {
   
}

export const BurndownCard = withStyles(styles)((props) => {

   const id = (props && props.id) || "burndown";
   const burndown = (props && props.burndown) ||  [];
   //const classes = (props && props.classes) || {};

   const chart = {
      options: {
         chart: {
            id: "burndown",
            toolbar: { show: false },
            animations: { enabled: false },
         },
         dataLabels: { enabled: false },
         yaxis: {
            min: 0,
            labels: { style: { fontSize: '11px' } }
         },
         xaxis: {
            categories: (burndown.length > 0 ? burndown.map((item) => { return item.hora }) : []),
            labels: {
               hideOverlappingLabels: true,
               style: { fontSize: '12px' }
            },
         },
         colors: ["#fc7d00", '#008FFB', '#D7263D', '#66DA26'],
         fill: {
            type: ['line', 'gradient', 'gradient', 'solid'],
            opacity: [0.8, 0.6, 0.5, 0.2],
            gradient: {
               shade: 'dark',
               type: 'vertical',
               shadeIntensity: 0.3,
               inverseColors: false,
               opacityFrom: 0.7,
               opacityTo: 1,
               stops: [0, 100]
            }
         },
         legend: { show: false },
         tooltip: {
            enabled: true,
            y: {
               formatter: function(value, context) {
                  let result = "";
                  if(context.seriesIndex === 1) {    //DENTRO IDEAL
                     result = context.series[1][context.dataPointIndex] === context.series[3][context.dataPointIndex]
                            && context.series[1][context.dataPointIndex] !== context.series[2][context.dataPointIndex] ? null : value;
                  }
                  else if(context.seriesIndex === 2) {  //FORA IDEAL
                     result = context.series[2][context.dataPointIndex] === context.series[1][context.dataPointIndex] ? null : value;
                  }
                  else if(context.seriesIndex === 3) { //IDEAL
                     result = value;
                  }
                  else if(context.seriesIndex === 0) { //ORIGEM SLA
                     result = value === 0 ? null : value;
                  }
                  return result !== null ? `${result} ${result === 1 ? 'tarefa' : 'tarefas'}` : result
               }
            }
         },
         stroke: {
            width: [4, 2, 1, 0],
            curve: 'smooth'
         },
         markers: [0, 0, 0, 0],
      },
      series: [
         {
            name: "Inseridas Hoje",
            type: 'line',
            data: (burndown.length > 0 ? burndown.map((item) => { return item.origem_sla }) : [])
         },
         {
            name: "Dentro Ideal",
            stacked: true,
            type: 'area',
            data: (burndown.length > 0 ? burndown.map((item) => { return item.dentro_ideal }) : [])
         },
         {
            name: "Fora Ideal",
            stacked: true,
            type: 'area',
            data: (burndown.length > 0 ? burndown.map((item) => { return (item.fora_ideal ? item.fora_ideal : null) }) : [])
         },
         {
            name: "Ideal",
            type: 'area',
            data: (burndown.length > 0 ? burndown.map((item) => { return item.ideal }) : []),
         }
      ]
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"BURNDOWN - TAREFAS DIA"}>
            <div className="mixed-chart">
               <Chart
                  options={chart.options}
                  series={chart.series}
                  type="area"
                  height={285}
               />
            </div>
         </DashCard>
      </React.Fragment>
   )
});

export default BurndownCard;