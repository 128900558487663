import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";

import { DashCard } from "../components/components";

const styles = {
   
}

export const HistoricoEstoqueCard = withStyles(styles)((props) => {

   const id = (props && props.id) || "filas";
   const filas = (props && props.filas) ||  [];
   //const classes = (props && props.classes) || {};

   const chart = {
      options: {
         chart: {
            id: "filas",
            toolbar: { show: false },
            animations: { enabled: false },
            stacked: true
         },
         dataLabels: { enabled: false },
         yaxis: {
            min: 0,
            labels: { style: { fontSize: '11px' } }
         },
         xaxis: {
            categories: (filas.length > 0 ? filas.map((item) => { return item.data }) : []),
            labels: {
               hideOverlappingLabels: true,
               style: { fontSize: '12px' }
            },
         },
         colors: ["#4472C4"],
         fill: {
            gradient: {
                opacityFrom: 1,
                opacityTo: 1,
              }
         },
         legend: { show: false },
         stroke: {
            width: [1,1,1,1,1],
            curve: 'smooth'
         },
         markers: [0, 0, 0, 0, 0],
      },
      series: [
         {
            name: "Geral",
            data: (filas.length > 0 ? filas.map((item) => { return item.total }) : [])
         }
      ]
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"HISTÓRICO DE ESTOQUE"}>
            <div className="mixed-chart">
               <Chart
                  options={chart.options}
                  series={chart.series}
                  type="area"
                  height={285}
               />
            </div>
         </DashCard>
      </React.Fragment>
   )
});

export default HistoricoEstoqueCard;