import React, { useState } from 'react';
import moment from "moment";
import { Typography, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { DashCard, DashPopover, DashTable } from "../components/components";

const styles = {
   value: {
      fontSize: '5.5rem',
      color: '#444444',
      marginBottom: '10px'
   },
   valueDetail: {
      fontSize: '0.7rem',
      color: '#444444',
      fontWeight: 'bold',
   },
   clickable: {
      cursor: "pointer",
		"&:hover": {
			opacity: 0.5
		}
   }
}

export const SustentacaoCard = withStyles(styles)((props) => {

   const [anchorEl, setAnchorEl] = useState(null);
   const [rows, setRows] = useState([]);
   const [columns, setColumns] = useState([]);
   const [popoverTitle, setPopoverTitle] = useState("");

   const id = (props && props.id) || "estoque";

   const indicadores = (props && props.tarefas.indicadores) || { indicadores: {sustentacao: 0}};
   const tarefas = (props && props.tarefas) ||  [];

   const estoque_detalhe = (props && props.estoque_detalhe) || { emergencias_suporte: [], previsao: [], pendentes: [], teste: [], razao: [], razao_semanal: [] };
   const classes = (props && props.classes) || {};

   let state = {
      razao_qtde: {entrante: 0, sainte: 0, razao: 0},
      razao_semanal_qtde: {entrante: 0, sainte: 0, razao: 0}
   }

   let entrante = 0;
   let sainte = 0;
   let entrante_semanal = 0;
   let sainte_semanal = 0;

   if (estoque_detalhe.razao) {
      (estoque_detalhe.razao).map((value, index) => {
         if(value.tipo === 'Entrada')
            entrante = entrante+1;
         else if(value.tipo === 'Saída')
            sainte = sainte+1;

         return null;
      });
      state.razao_qtde = {
         entrante: entrante, 
         sainte: sainte, 
         razao: entrante-sainte
      };
   }

   if (estoque_detalhe.razao_semanal) {
      (estoque_detalhe.razao_semanal).map((value, index) => {
         if(value.tipo === 'Entrada')
            entrante_semanal = entrante_semanal+1;
         else if(value.tipo === 'Saída')
            sainte_semanal = sainte_semanal+1;
            
         return null;
      });

      state.razao_semanal_qtde = {
         entrante: entrante_semanal, 
         sainte: sainte_semanal, 
         razao: entrante_semanal-sainte_semanal,
         razao_percentual: (entrante_semanal-sainte_semanal > 0 ? '+' : '') + Math.round((1 - (indicadores.sustentacao + indicadores.outras_tracks) / ((indicadores.sustentacao + indicadores.outras_tracks) + entrante_semanal-sainte_semanal)) * 100) + '%'
      };
   }

   const allColumns = {
      tarefas: [
         {
            identifier: "tarefa_id",
            label: "ID",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "cliente",
            label: "Cliente",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "tarefa_titulo",
            label: "Título",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "severidade",
            label: "Sev.",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "tipo_atendimento",
            label: "Tipo",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "recurso_nome",
            label: "Respons.",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "sla",
            label: "SLA",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "data_fim_prevista",
            label: "Data Prevista",
            variant: row => handleColumnVariant(row),
            format: value => (value && moment(value).format("DD/MM/YYYY")) || '---'
         }
      ],
      pendentes: [
         {
            identifier: "cliente",
            label: "Cliente"
         },
         {
            identifier: "descricao",
            label: "Atendimento"
         },
         {
            identifier: "severidade",
            label: "Severidade",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "categoria",
            label: "Categoria"
         },
         {
            identifier: "data_abertura",
            label: "Data Abert."
         },
         {
            identifier: "status_kmm",
            label: "Status"
         }
      ],
      atribuidos: [
         {
            identifier: "num_protocolo",
            label: "Protocolo",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "cliente",
            label: "Cliente",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "titulo",
            label: "Título",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "responsavel",
            label: "Respons.",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "sla",
            label: "SLA",
            variant: row => handleColumnVariant(row)
         }
      ],
      razao: [
         {
            identifier: "entrantes",
            label: "Entrantes"
         },
         {
            identifier: "saintes",
            label: "Saintes"
         },
         {
            identifier: "razao",
            label: "Razão"
         }
      ],
      razao_detalhes: [
         {
            identifier: "cliente",
            label: "Cliente",
         },
         {
            identifier: "descricao",
            label: "Descrição",
         },
         {
            identifier: "tipo_atendimento",
            label: "Tipo",
         },
         {
            identifier: "severidade",
            label: "Sev.",
         },
         {
            identifier: "responsavel",
            label: "Respons.",
         },
         {
            identifier: "hora",
            label: "Hora / SLA",
         },
         {
            identifier: "tipo",
            label: "Fluxo",
            variant: row => handleColumnVariant(row)
         },
      ]
   }

   function openPopover(event, rows = [], columns = [], title = "") {
      setAnchorEl(event.target);
      setRows(rows);
      setColumns(columns);
      setPopoverTitle(title);
   }

   function handleColumnVariant(row) {
      if (row.executando) {
         return "info";
      }else if (row.finalizado || row.tipo === 'Saída') {
         return "success";
      }else if (row.atrasado || row.sla_vencido === 1 || row.tipo === 'Entrada' || row.severidade === 'Emergência') {
         return "danger";
      }else if (row.sla_vencido === 2 || row.severidade === 'Severidade 1'){  // à vencer
         return "warning";
      }else{
         return "default";
      }
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"SUSTENTAÇÃO"}>
            <div style={{ display: "flex", flex: 1 }}>
               <div style={{ flexBasis: "50%" , marginTop: "24px"}} onClick={event => openPopover(event, tarefas.lista_corretivas, allColumns.tarefas, 'CORRETIVAS')} className={`${classes.clickable} ${classes.cardValue}`}>
                  <Typography variant="h1" color="textPrimary" align="center" className={classes.value}>
                     {indicadores.sustentacao}
                  </Typography>
               </div>               
               <div style={{ flex: "1" }}>
                  <div onClick={event => openPopover(event, estoque_detalhe.emergencias_suporte, allColumns.pendentes, 'Emergências no Suporte')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
                     <Typography style={{ fontSize: "12px" }} color="textPrimary" align="center"> Emergências no Suporte </Typography>
                     <Typography variant="h6" color="textPrimary" align="center"> {estoque_detalhe.emergencias_suporte.length} </Typography>
                  </div>
                  <Divider />
                  <div onClick={event => openPopover(event, estoque_detalhe.previsao, allColumns.pendentes, 'Em Fluxo no Suporte')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
                     <Typography style={{ fontSize: "12px" }} color="textPrimary" align="center"> Em Fluxo no Suporte </Typography>
                     <Typography variant="h6" color="textPrimary" align="center"> {estoque_detalhe.previsao.length} </Typography>
                  </div>
                  <Divider />
                  <div onClick={event => openPopover(event, estoque_detalhe.pendentes, allColumns.pendentes, 'Pendentes Alocação')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
                     <Typography style={{ fontSize: "12px" }} color="textPrimary" align="center"> Pendentes Alocação </Typography>
                     <Typography variant="h6" color="textPrimary" align="center"> {estoque_detalhe.pendentes.length} </Typography>
                  </div>
                  <Divider />
                  <div onClick={event => openPopover(event, estoque_detalhe.razao_semanal, allColumns.razao_detalhes, 'Razão Semanal')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
                     <Typography style={{ fontSize: "12px" }} color="textPrimary" align="center"> Razão semanal </Typography>
                     <Typography variant="h6" color="textPrimary" title={"Entrantes: "+ state.razao_semanal_qtde.entrante + "\nSaintes: "+ state.razao_semanal_qtde.sainte + "\nRazão (%): "+state.razao_semanal_qtde.razao_percentual} align="center">{state.razao_semanal_qtde.razao}</Typography>
                  </div>
                  <Divider />
                  <div onClick={event => openPopover(event, estoque_detalhe.razao, allColumns.razao_detalhes, 'Razão')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
                     <Typography style={{ fontSize: "12px" }} color="textPrimary" align="center"> Razão do dia</Typography>
                     <Typography variant="h6" color="textPrimary" title={"Entrantes: "+ state.razao_qtde.entrante + "\nSaintes: "+ state.razao_qtde.sainte} align="center"> { state.razao_qtde.razao } </Typography>
                  </div>
                  <Divider />
               </div>
            </div>
         </DashCard>
         <DashPopover anchor={anchorEl} onClose={_ => setAnchorEl(null)} title={popoverTitle}>
            <DashTable columns={columns} rows={rows} emptyMessage="Nenhuma tarefa disponível" />
         </DashPopover>
      </React.Fragment>
   )
});

export default SustentacaoCard;