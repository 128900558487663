import React, { useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import lottie from "lottie-web";

import animationSuccess from "../../assets/animation-success.json"
import animationDanger from "../../assets/animation-danger.json"
import animationBad from "../../assets/animation-bad.json"
import animationGood from "../../assets/animation-good.json"

const styles = {
}

export const Animation = withStyles(styles)((props) => {

    const animation = null;
    const animationType = (props && props.type); 
    const animationText = (props && props.text);

    useEffect(() => {
    
        if (animation !== null) {
            animation.destroy();
        }
    
        var animationData = null;
        var loop = false;
        if(animationType === 'success'){
            animationData = animationSuccess;
        }else if(animationType === 'danger'){
            animationData = animationDanger;
            loop = true;
        }else if(animationType === 'bad'){
            animationData = animationBad;
            loop = false;
        }else if(animationType === 'good'){
            animationData = animationGood;
            loop = true;
        }

        lottie.loadAnimation({
            container: document.getElementById('animation'),
            renderer: 'svg',
            autoplay: true,
            loop: loop,
            animationData: animationData,
        });

        // eslint-disable-next-line
    }, []);

    

    return (
        <React.Fragment>
            <div id="all" className={`all all-${animationType}`}>
			    <div id="animation" className="animation-layer"></div>
                <div className="animationText">
                    {animationText.split("\n").map((i,key) => {
                        return <div key={key}>{i}</div>;
                    })}
                </div>
            </div>
        </React.Fragment>
     )
});

export default Animation;