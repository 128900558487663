import "./login.css";
import React, { Component } from "react";
import { withSnackbar } from "notistack";
import {
    TextField,
    Button,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";

import { ReactComponent as Logo } from "../assets/logoKMM.svg";
import { ReactComponent as UndrawDashboard } from "../assets/undraw_dashboard.svg";
import { ReactComponent as Icon } from "../assets/iconKMM.svg";
import { Fetch, Store } from "../storage";

class Login extends Component {
    state = {
        user: "",
        password: "",
        saveLogin: false
    };

    componentDidMount() {
        if (Fetch.userInfo()) {
            this.setState({ user: Fetch.userInfo(), saveLogin: true });
        }
    }

    onLogin = () => {
        const { user, password, saveLogin } = this.state;
        if (!user || !password) {
            this.props.enqueueSnackbar("Preencha todos os campos.", {
                variant: "error"
            });
            return;
        }
        if (saveLogin) {
            Store.userInfo(user)
        }
        if (this.props.onLogin) {
            this.props.onLogin(user, password);
        }
    };

    render() {
        const { user, password, saveLogin } = this.state;

        return (
            <div className="login-page">
                <div className="login-icon">
                    <UndrawDashboard />
                </div>
                <div className="login-card">
                    <Logo/>
                    <label className="login-title">Controle de Produção</label>
                    <TextField
                        type="email"
                        label="Usuário"
                        variant="filled"
                        value={user}
                        onKeyUp={event => {
                            if (event.keyCode === 13) this.onLogin();
                        }}
                        onChange={event =>
                            this.setState({ user: event.target.value })
                        }
                    />
                    <TextField
                        type="password"
                        label="Senha"
                        variant="filled"
                        value={password}
                        onKeyUp={event => {
                            if (event.keyCode === 13) this.onLogin();
                        }}
                        onChange={event =>
                            this.setState({ password: event.target.value })
                        }
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={saveLogin}
                                color="primary"
                                onChange={event =>
                                    this.setState({
                                        saveLogin: event.target.checked
                                    })
                                }
                            />
                        }
                        label="Lembrar Usuário"
                    />

                    <Button
                        onClick={this.onLogin}
                        variant="contained"
                        color="primary"
                    >
                        Entrar
                    </Button>
                    <div className="poweredBy">
                        <label>Powered by KMM®</label>
                        <Icon />
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(Login);
