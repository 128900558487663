import React from "react";
import ReactDOM from "react-dom";
//import App from './dashboard/app';
import App from "./main/app";
import { SnackbarProvider } from "notistack";
import { HashRouter } from "react-router-dom";

ReactDOM.render(
    <HashRouter>
        <SnackbarProvider>
            <App />
        </SnackbarProvider>
    </HashRouter>,
    document.getElementById("root")
);
