import "./app.css";
import React, { Component } from "react";
import { withSnackbar } from "notistack";
import KmmService, { LoginService } from "../kmm-service/src/";
import Dashboard from "../dashboard/dashboad";
import Login from "../login/login";
import { withRouter } from 'react-router-dom';
import { Store, Fetch, Delete } from "../storage";

class App extends Component {

    state = {
        carousel: 'off'
    }

    isAuth = () => {
        return Fetch.token();
    };

    onLogin = (user, password) => {
        LoginService(user, password, response => {
            if (response.success && response.result) {
                this.props.enqueueSnackbar("Bem Vindo", {
                    variant: "success"
                });
                Store.token(response.result.token)
                KmmService("M1504", "getEquipe", { }, response => {
                    Store.userTeam(response.success ? response.result.equipe : {});
                    this.forceUpdate();
                });
            } else {
                this.props.enqueueSnackbar(response.message, {
                    variant: "error"
                });
            }
        });
    };

    onLogout = () => {
        Delete.token();
        this.forceUpdate();
    };

    render() {
        const queryString = require('query-string');
        let params = queryString.parse(this.props.location.search);
        
        if(params.carousel !== this.state.carousel){
            this.setState({carousel: params.carousel});
        }
    
        return (
            <React.Fragment>
                {!this.isAuth() && <Login onLogin={this.onLogin} />}
                {this.isAuth() && <Dashboard equipe={params.equipe} user='' onLogout={this.onLogout} carousel={this.state.carousel}/>}
            </React.Fragment>
        );
    }
}

export default withRouter(withSnackbar(App));
