import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";

import { DashCard } from "../components/components";

const styles = {
   
}

export const HistoricoEstoqueTracksCard = withStyles(styles)((props) => {

   const id = (props && props.id) || "filas";
   const filas = (props && props.filas) ||  [];
   //const classes = (props && props.classes) || {};

   let maxPoint = 100;
   filas.forEach(item => {
      let totalTemp = item.sustentacao + item.implantacao + item.fasttrack + item.evolucao + item.debitostecnicos;
      maxPoint = (totalTemp > maxPoint ? totalTemp : maxPoint);
   });  

   const chart = {
      options: {
         chart: {
            id: "filas",
            toolbar: { show: false },
            animations: { enabled: false },
            stacked: true
         },
         dataLabels: { enabled: false },
         yaxis: {
            min: 0,
            max: maxPoint,
            labels: { style: { fontSize: '11px' } }
         },
         xaxis: {
            categories: (filas.length > 0 ? filas.map((item) => { return item.data }) : []),
            labels: {
               hideOverlappingLabels: true,
               style: { fontSize: '12px' }
            },
         },
         colors: ["#ED7D31", '#C9C9C9', '#FCC000', '#4472C4', '#70AD47'],
         fill: {
            gradient: {
                opacityFrom: 1,
                opacityTo: 1,
              }
         },
         legend: { show: false },
         stroke: {
            width: [1,1,1,1,1],
            curve: 'smooth'
         },
         markers: [0, 0, 0, 0, 0],
      },
      series: [
         {
            name: "Sustentação",
            data: (filas.length > 0 ? filas.map((item) => { return item.sustentacao }) : [])
         },
         {
            name: "Implantação",
            data: (filas.length > 0 ? filas.map((item) => { return item.implantacao }) : [])
         },
         {
            name: "Fast Track",
            data: (filas.length > 0 ? filas.map((item) => { return item.fasttrack }) : [])
         },
         {
            name: "Evolução",
            data: (filas.length > 0 ? filas.map((item) => { return item.evolucao }) : []),
         },
         {
            name: "Débitos técnicos",
            data: (filas.length > 0 ? filas.map((item) => { return item.debitostecnicos }) : []),
         }
      ]
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"HISTÓRICO DE TRACKS"}>
            <div className="mixed-chart">
               <Chart
                  options={chart.options}
                  series={chart.series}
                  type="area"
                  height={285}
               />
            </div>
         </DashCard>
      </React.Fragment>
   )
});

export default HistoricoEstoqueTracksCard;