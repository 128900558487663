import React from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { DashTable } from "../components/components";

const styles = {
}

export const DesempenhoIndividualCard = withStyles(styles)((props) => {

   //const classes = (props && props.classes) || {};
   const tarefas = (props && props.tarefas) || [];

   const columns = {
      tarefas: [
         {
            identifier: "tarefa_id",
            label: "ID",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "titulo",
            label: "Título",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "data_fim_prevista",
            label: "Data Fim Prevista",
            variant: row => handleColumnVariant(row),
            format: value => (value && moment(value).format("DD/MM/YYYY")) || "---"
         }
      ]
   }

   function handleColumnVariant(row) {
      if (row.executando) {
         return "info";
      }
      else if (row.finalizado) {
         return "success";
      }
      else if (row.atrasado) {
         return "danger";
      }
      else {
         return "default";
      }
   }

   return (
      <React.Fragment>
         <DashTable columns={columns.tarefas} rows={tarefas} emptyMessage="Nenhuma tarefa disponível" />
      </React.Fragment>
   )
});

export default DesempenhoIndividualCard;