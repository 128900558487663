import React, { useState } from 'react';
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { DashCard, DashPopover, DashTable } from "../components/components";

const styles = {
   value: {
      marginTop: 50,
		marginBottom: 25,
		fontSize: '5rem',
		color: '#444444'
   },
   valueDetail: {
      fontSize: '1rem',
		color: '#444444'
   }
};

export const EmergenciasExecutadasCard = withStyles(styles)((props) => {

   const [anchorEl, setAnchorEl] = useState(null);
   const [rows, setRows] = useState([]);
   const [columns, setColumns] = useState([]);
   const [popoverTitle, setPopoverTitle] = useState("");

   const id = (props && props.id) || "emergencias_executadas";
   const emergencias = (props && props.indicadores.emergencias) ||  { tempo: 0, qtde: 0 };
   const lista = (props && props.indicadores.emergencias_lista) ||  [];
   const classes = (props && props.classes) || {};

   const allColumns = {
      emergencias: [
         {
            identifier: "tarefa_id",
            label: "ID",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "cliente",
            label: "Cliente",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "tarefa_titulo",
            label: "Título",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "recurso_nome",
            label: "Recurso",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "aberto_em",
            label: "Aberto em",
            variant: row => handleColumnVariant(row)
         },
      ]
   };

   function openPopover(event, rows = [], columns = [], title = "") {
      setAnchorEl(event.target);
      setRows(rows);
      setColumns(columns);
      setPopoverTitle(title);
   }

   function handleColumnVariant(row) {
      if (row.finalizado) {
         return "success";
      }else if (row.atrasado) {
         return "danger";
      }else {
         return "default";
      }
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"EMERGÊNCIAS EXECUTADAS"} variant={emergencias.qtde > 0 ? 'danger' : 'primary'} className={classes.clickable}  onClick={event => openPopover(event, lista, allColumns.emergencias, 'Emergências executadas')}>
            <div>
               <Typography variant="h1" color="textPrimary" align="center" className={classes.value}>
                  {emergencias.tempo}
               </Typography>
               <Typography variant="h1" color="textPrimary" align="center" className={classes.valueDetail}>
                  {emergencias.qtde} emergência{emergencias.qtde > 1 && <span>s</span>}
               </Typography>
            </div>
         </DashCard>
         <DashPopover anchor={anchorEl} onClose={_ => setAnchorEl(null)} title={popoverTitle}>
            <DashTable columns={columns} rows={rows} emptyMessage="Nenhuma emergência" />
         </DashPopover>
      </React.Fragment>
   )
});

export default EmergenciasExecutadasCard;