import React from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { red, blue, green, yellow } from '@material-ui/core/colors';

const styles={
   TableRow: {
      backgroundColor: "#FFFFFF",
		color: '#444444',
		fontSize: '1rem',
		fontWeight: 'bold'
   },
   TableCell: {
      backgroundColor: "#FFFFFF",
		color: '#444444'
   },
   TableCellBig: {
      backgroundColor: "#FFFFFF",
      color: '#444444',
      fontSize: '1.3rem',
   },
   CellDanger: {
      backgroundColor: red[700],
      color: "white"
   },
   CellSuccess: {
      backgroundColor: green[700],
      color: "white"
   },
   CellInfo: {
      backgroundColor: blue[700],
      color: "white"
   },
   CellWarning: {
      backgroundColor: yellow[700],
      color: "white"
   },
   emptyContainer: {
      display: "flex",
      alignItems: "center",
   },
   emptyText: {
      padding: "16px",
      flex: 1
   },
   icon: {
      color: '#CCCCCC',
      flex: 1,
      width: "unset",
      height: "unset"
   },
   clickable: {
      cursor: "pointer",
      "&:hover": {
         opacity: "0.5"
      }
   },
   bgWhite: {
      backgroundColor: '#FFFFFF'
   }
}

export const DashTable = withStyles(styles)((props) => {

   const classes = (props && props.classes) || {};

   const columns = (props && props.columns) || [];
   const rows = (props && props.rows) || [];
   const emptyMessage = (props && props.emptyMessage) || "Nenhum registo encontrado.";

   const tableCellBig = (props && props.tableCellBig) || false;

   const onRowClick = (props && props.onRowClick) || null;

   function getVariant(variant = null) {
      switch(variant) {
         case "info":
            return classes.CellInfo;
         case "danger":
            return classes.CellDanger;
         case "warning":
            return classes.CellWarning;
         case "success":
            return classes.CellSuccess;
         default:
            return classes.TableCell;
      }
   }

   function styleHeader(column) {
      if (column.styled) {
         return column.styled(column.label)
      }
      return column.label;
   }

   function styleCell(row, column) {
      const value = column.format ? column.format(row[column.identifier]) : row[column.identifier];
      if (column.style) {
         return column.style(value, row);
      }
      return value;
   }

   function handleClick(item, event) {
      if (onRowClick) {
         onRowClick(item, event);
      }
   }
   
   return (
      <React.Fragment>
         {rows.length > 0 && (
            <Table size="small">
               <TableHead>
                  <TableRow className={classes.TableRow}>
                     {columns.map((column, i) => {
                        return (
                           <TableCell key={i} className={`${tableCellBig ? classes.TableCellBig : classes.TableCell} ${column.mobile === false ? 'noMobile' : '' }`}>
                              {styleHeader(column)}
                           </TableCell>
                        );
                     })}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {rows.map((row, index) => {
                     return (
                        <TableRow key={`row#${index}`} className={`${classes.TableRow} ${onRowClick ? classes.clickable : ''}`} onClick={(event) => handleClick(row, event)}>
                           {columns.map((column, colIndex) => {
                              return (
                                 <TableCell key={`rowcell#${index}#${colIndex}`} title={column.popover ? column.popover(row) : null} className={`${tableCellBig ? classes.TableCellBig : classes.TableCell} ${column.variant ? getVariant(column.variant(row)) : getVariant()} ${column.mobile === false ? 'noMobile' : '' }`}>
                                    {styleCell(row, column)}
                                 </TableCell>
                              );
                           })}
                        </TableRow>
                     );
                  })}
                  
               </TableBody>
            </Table>
         )}
         {rows.length === 0 && (
            <div className={classes.emptyContainer}>
               <CheckCircleOutline className={classes.icon} />
               <Typography className={classes.emptyText}>
                  {emptyMessage}
               </Typography>
            </div>
         )}
      </React.Fragment>
   )
});

export default DashTable;