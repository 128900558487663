import React from "react";
import { Popover, IconButton, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";

const styles = {
   Popover: {
      overflowX: "auto",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column"
   },
   header: {
      width: "100%", 
      display: "flex", 
      alignItems: "center"
   },
   closeButton: {
      marginLeft: "auto"
   },
   title: {
      fontWeight: "bold",
      marginLeft: "16px"
   }
}

export const DashPopover = withStyles(styles)(props => {
   
   const children = (props && props.children) || null;
   const classes = (props && props.classes) || {}
   const anchor = (props && props.anchor) || null
   const onClose = (props && props.onClose) || (() => {});
   const title = (props && props.title) || ""
   return (
      <Popover
         className={classes.Popover}
         open={Boolean(anchor)}
         anchorEl={anchor}
         onClose={onClose}
      >
         <div className={classes.header}>
            <Typography className={classes.title}>{title}</Typography>
            <IconButton className={classes.closeButton} onClick={onClose}>
               <Clear />
            </IconButton>
         </div>
         {children}
      </Popover>
   )
});

export default DashPopover;