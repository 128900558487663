
import hmacSHA512 from 'crypto-js/hmac-sha512';

const __KEY__ = "__KMM_PRODUCAO_WEB__";

export const Store = {

    token(newValue) {
        const oldValue = Fetch.token();
        if (oldValue !== newValue) {
            localStorage[hmacSHA512("token", __KEY__).toString()] = newValue;
        }
    },

    userInfo(newValue) {
        const oldValue = Fetch.userInfo();
        if (oldValue !== newValue) {
            localStorage[hmacSHA512("userInfo", __KEY__).toString()] = newValue;
        }
    },

    userTeam(newValue) {
        localStorage[hmacSHA512("userTeam", __KEY__).toString()] = JSON.stringify(newValue);
    }

}

export const Fetch = {

    token() {
        return localStorage[hmacSHA512("token", __KEY__).toString()];
    },

    userInfo() {
        return localStorage[hmacSHA512("userInfo", __KEY__)];
    },

    userTeam() {
        return JSON.parse(localStorage[hmacSHA512("userTeam", __KEY__).toString()].toString());
    }

}

export const Delete = {
    
    token() {
        localStorage.removeItem(hmacSHA512("token", __KEY__).toString());
    },

    userInfo() {
        localStorage.removeItem(hmacSHA512("userInfo", __KEY__).toString());
    },

    userTeam() {
        localStorage.removeItem(hmacSHA512("userTeam", __KEY__).toString());
    }

}