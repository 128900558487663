import { Fetch } from "../../storage";

function KmmService(module, operation, parameters, callback) {
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json"
    };

    if (Fetch.token()) {
        //headers["Authorization"] =
        //"eyJpYXQiOjE1NDU4NDYyMTAsImp0aSI6Ik16ZzNORFV6T1E9PSIsImlzcyI6Ind3dy5rbW0uY29tLmJyIiwibmJmIjoxNTQ1ODQ2MjE1LCJleHAiOjE1NjEzOTgyMTUsImRhdGEiOnsidXNlcm5hbWUiOiJrbW1fYmkiLCJwYXNzd29yZCI6ImNBZytwaDVvT21NNXVmS3NoVTJGWXc9PSIsImNvZF9nZXN0YW8iOjksImZpbGlhaXMiOm51bGx9fQ==.ZjEzMjQxZTliZWM1Nzk1YmVhMDA3MTg2NGNlMjNkYWNmMjc1MzdmNjk0ZDBkZjk0NWZlYWY0MTc5ZjU5NDZjMQ==";
        headers["Authorization"] = Fetch.token();
    }

    const body = {
        module: module,
        operation,
        parameters: parameters
    };

    fetch("https://www.kmm.com.br/_remote/gateway.php", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    })
        .then(response => response.json())
        .then(response => {
            if (callback) {
                callback(response);
            }
        })
        .catch(response => {
            console.log('ERROR', callback, response);
            if (callback) {
                callback(response);
            }
        });
}

export const LoginService = (user, password, callback) => {
    const parameters = {
        username: user,
        password: password,
        cod_gestao: 9
    };
    KmmService("LOGON", "LOGON", parameters, response => {
        if (callback) {
            callback(response);
        }
    });
};

export default KmmService;
