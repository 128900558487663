import React, { useState } from 'react';
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { ArrowForward, ArrowBack } from "@material-ui/icons";

import { DashCard, DashPopover, DashTable } from "../components/components";
import { DesempenhoIndividualCard } from "../cards/cards";
import KmmService from "../../kmm-service/src/";


const styles = {
   value: {
      fontSize: '5.5rem',
		color: '#444444'
   },
   valueDetail: {
      fontSize: '3.5rem',
		color: '#d32f2f'
   },
   projectRow: {
      display: "flex", 
      justifyContent: "space-between", 
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
			opacity: 0.5
		}
   },
   greenCircle: {
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		backgroundColor: "#6FCF97",
      margin: "4px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
	},
   grayCircle: {
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		backgroundColor: "#DDDDDD",
      margin: "4px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
	},
	redCircle: {
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		backgroundColor: "#EB5757",
      margin: "4px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
   },
   recursosRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      flex: 1,
      overflow: "auto"
   },
   clickable: {
      cursor: "pointer",
      "&:hover": {
         opacity: "0.5"
      }
   }
}

export const RecursosCard = withStyles(styles)((props) => {

   const [isOpen, setOpen] = useState(true);
   const [anchorEl, setAnchorEl] = useState(null);
   const [tarefas, setTarefas] = useState([]);
   const [indicadores, setIndicadores] = useState([]);
   const [title, setTitle] = useState("Tarefas");

   const id = (props && props.id) || "recursos";
   const recursos = (props && props.recursos) || [];
   const desempenho = (props && props.desempenho) || "0%";
   const classes = (props && props.classes) || {};

   const columns = {
      recursoHidden: [
         {
            identifier: "foto",
            label: "",
            style: (value, row) => {
               return (
                  <div className={row && row.ativo === 1? classes.greenCircle : row.ativo === 2 ? classes.grayCircle :classes.redCircle}>
                     <label className="recurso-iniciais">{row.iniciais}</label>
                  </div>
               );
            },
            popover: row => row.recurso
         }
      ],
      recursoCollapsed: [
         {
            identifier: "foto",
            label: "",
            style: (value, row) => {
               return (
                  <div className={row && row.ativo === 1 ? classes.greenCircle : row && row.ativo === 2 ? classes.grayCircle : classes.redCircle}></div>
               );
            },
            popover: row => row.recurso
         },
         {
            identifier: "recurso",
            label: "Nome"
         },
         {
            identifier: "previstas",
            label: "Prev",
            mobile: false
         },
         {
            identifier: "realizadas",
            label: "Realiz",
            mobile: false
         }
      ],
      tarefas: [
         {
            identifier: "tarefa_id",
            label: "ID",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "titulo",
            label: "Título",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "data_fim_prevista",
            label: "Data Fim Prevista",
            variant: row => handleColumnVariant(row),
            format: value => (value && moment(value).format("DD/MM/YYYY")) || "---"
         }
      ]
   }

   function handleColumnVariant(row) {
      if (row.executando) {
         return "info";
      }
      else if (row.finalizado) {
         return "success";
      }
      else if (row.atrasado) {
         return "danger";
      }
      else {
         return "default";
      }
   }

   function handlePopoverClose() {
      setAnchorEl(null);
      setTarefas([]);
      setIndicadores([]);
   }

   function toggleRecursos() {
      const element = document.getElementById(id);
      element.classList.toggle("open");
      setOpen(!isOpen);
   }
   
   function getRecursoTarefas(row, event) {
      const target = event.target;
      const recurso = row.recurso;
		KmmService("M1504", "getRecursoTarefas", { "recurso_id": row.recurso_id }, response => {
			if (response.success) {
            setTarefas(response.result.tarefas);
         }

         setAnchorEl(target);
         setTitle(`${recurso}`)

         KmmService("M1504", "getRecursoIndicadores", { "recurso_id": row.recurso_id }, response => {
            if (response.success) {
               setIndicadores(response.result.indicadores);
            }
         });
		});
   }

   const isMobile = window.matchMedia("(max-width: 976px)").matches;

   return (
      <div id={id} className="recursos open">
         <DashCard title={(isOpen || isMobile) ? "RECURSOS / PONTOS" : ""} details={desempenho} icon={!isMobile ? (isOpen ? <ArrowForward /> : <ArrowBack />) : null} iconClick={toggleRecursos}>
            <div className={`onlyMobile ${classes.recursosRow}`} >
               {recursos.map(item => {
                  return (
                     <div key={item.recurso} title={item.recurso} className={`${item && item.ativo === 1 ? classes.greenCircle : item.ativo === 2 ? classes.grayCircle : classes.redCircle} ${classes.clickable}`} onClick={event => getRecursoTarefas(item, event)}>
                        <label className="recurso-iniciais">{item.iniciais}</label>
                     </div>
                  )
               })}
            </div>
            <div className="noMobile tableRecursos">
               <DashTable columns={ isOpen ? columns.recursoCollapsed : columns.recursoHidden } rows={recursos} emptyMessage="Nenhum recurso disponível" onRowClick={getRecursoTarefas} />
            </div>
         </DashCard>
         <DashPopover anchor={anchorEl} onClose={handlePopoverClose} title={title}>
            <DesempenhoIndividualCard tarefas={tarefas} indicadores={indicadores}></DesempenhoIndividualCard>
         </DashPopover>
      </div>
   )
});

export default RecursosCard;