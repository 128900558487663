import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";
import { DashCard } from "../components/components";

const styles = {

}

export const RecorrenciaCard = withStyles(styles)((props) => {

   const id = (props && props.id) || "recorrencia";
   const { recorrencia } = (props && props) || [];

   const chart = {
      options: {
         chart: {
            id: "recorrenciachart",
            toolbar: { show: false },
            animations: { enabled: false },
            offsetY: -10
         },
         plotOptions: {
            radialBar: {
               startAngle: -135,
               endAngle: 135,
               hollow: {
                  margin: 0,
                  size: '70%',
                  background: '#fff',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front'
               },
               track: {
                  background: '#fff',
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                     enabled: true,
                     top: -3,
                     left: 0,
                     blur: 4,
                     opacity: 0.35
                  }
               },
               dataLabels: {
                  show: true,
                  name: {
                     offsetY: -10,
                     show: true,
                     color: '#888',
                     fontSize: '15px'
                  },
                  value: {
                     formatter: function (val) {
                        return parseFloat(val).toFixed(1) + '%';
                     },
                     color: '#111',
                     fontSize: '30px',
                     show: true,
                  }
               }
            }
         },
         fill: {
            type: 'solid',
            colors: ['#D7263D']
         },
         stroke: {
            lineCap: 'round'
         },
         labels: ['Geral'],
      },
      series: [recorrencia.geral]
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"RECORRÊNCIAS"}>
            <div className="mixed-chart">
               <Chart
                  options={chart.options}
                  series={chart.series}
                  type="radialBar"
                  height={210}
               />
            </div>
         </DashCard>
      </React.Fragment>
   )
});


export default RecorrenciaCard;