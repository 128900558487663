import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";

import { DashCard, DashPopover, DashTable } from "../components/components";

const styles = {
   
}

export const TipoDemandaCard = withStyles(styles)((props) => {
   
   const [rows, setRows] = useState([]);
   const [anchorEl, setAnchorEl] = useState(null);

   const id = (props && props.id) || "tipo_demanda";
   const indicadores = (props && props.indicadores) || [{ tipo: 'Evolução', tempo: 0, tempo_formatado: '0:00' }];
   const tipoDemanda = (props && props.tipoDemanda) || { evolucao: [], retrabalho: [], suporte:[], organizacao: [] };
   //const classes = (props && props.classes) || {};
   
	function formatTempoTarefa(value) {
		if (value === null || value === undefined) return "";
		let hours = Math.floor(value*24);
		hours = (hours < 10) ? `0${hours}` : hours;
		let minutes = Math.floor(((value*24)%1)*60);
		minutes = (minutes < 10) ? `0${minutes}` : minutes;
		return `${hours}:${minutes}`;
	}

   const columns = [
      {
         identifier: "tarefa_id",
         label: "ID"
      },
      {
         identifier: "tarefa_titulo",
         label: "Título"
      },
      {
         identifier: "recurso_nome",
         label: "Recurso"
      },
      {
         identifier: "tarefa_pontos",
         label: "Pontos"
      },
      {
         identifier: "tarefa_tipo",
         label: "Tipo Tarefa",
         mobile: false
      },
      {
         identifier: "atendimento_tipo",
         label: "Tipo Atendimento",
         mobile: false
      }
   ];

   const chart = {
      options: {
         chart: {
            id: "tipodemanda",
            animations: { enabled: false },
         },
         labels: (indicadores.length > 0 ? indicadores.map((item) => { return item.tipo }) : []),
         legend: {
            position: 'bottom',
            fontSize: '10px',
         },
         tooltip: {
            enabled: true,
            y: {
               formatter: function(value) {
                   return formatTempoTarefa(value);
               }
              }
         },
         colors: ['#008FFB', '#D7263D', '#FEB019', '#546E7A'],
         dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
               fontSize: '12px',
            },
            formatter: function (val, opts) {
               return val.toFixed() + '%';
            }
         }
      },
      series: (indicadores ? indicadores.map((item) => { return item.tempo }) : []),
      events: {
         dataPointSelection: function(event, chartContext, config) {
            let tarefas = [];
            switch(config.w.config.labels[config.dataPointIndex]){
               case "Evolução":
                     tarefas = tipoDemanda.evolucao;
                  break;
               case "Retrabalho":
                  tarefas = tipoDemanda.retrabalho;
                  break;
               case "Suporte":
                  tarefas = tipoDemanda.suporte;
                  break;
               case "Organização":
                  tarefas = tipoDemanda.organizacao;
                  break; 
               default:
            }
            setRows(tarefas);
         }
      }
   };

   return (
      <React.Fragment>
         <DashCard id={id} title={"TIPO DE DEMANDA"}>
            <div className="mixed-chart">
               <Chart
                  ref={ref=> {
                     if (ref && ref.chart) {
                        ref.chart.addEventListener("dataPointSelection", chart.events.dataPointSelection);
                     }
                  }}
                  options={chart.options}
                  series={chart.series}
                  type="donut"
                  height={273}
               />
            </div>
         </DashCard>
         <DashPopover anchor={anchorEl} onClose={_ => setAnchorEl(null)} title="Tarefas">
            <DashTable columns={columns} rows={rows} emptyMessage="Nenhuma tarefa disponível" />
         </DashPopover>
      </React.Fragment>
   )
});

export default TipoDemandaCard;