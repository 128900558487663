import React, { useState } from 'react';
import moment from "moment";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { DashCard, DashPopover, DashTable } from "../components/components";

const styles = {
   value: {
      fontSize: '5.5rem',
		color: '#444444'
   },
   clickable: {
      cursor: "pointer",
		"&:hover": {
			opacity: 0.5
      },
      marginTop: '20px',
      marginBottom: '30px'
   }
}

export const OutrosCard = withStyles(styles)((props) => {

   const [anchorEl, setAnchorEl] = useState(null);
   const [rows, setRows] = useState([]);
   const [columns, setColumns] = useState([]);
   const [popoverTitle, setPopoverTitle] = useState("");

   const id = (props && props.id) || "projetos";
   const indicadores = (props && props.tarefas.indicadores) || { indicadores: {aprs: 0, codereview: 0}};
   const tarefas = (props && props.tarefas) ||  [];

   const classes = (props && props.classes) || {};

   function handleColumnVariant(row) {
      if (row.executando) {
         return "info";
      }else if (row.atrasado || row.sla_vencido === 1) {
         return "danger";
      }else if (row.sla_vencido === 2){  // à vencer
         return "warning";
      }else if (row.finalizado) {
         return "success";
      }else {
         return "default";
      }
   }

   const allColumns = {
      tarefas: [
         {
            identifier: "tarefa_id",
            label: "ID",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "cliente",
            label: "Cliente",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "tarefa_titulo",
            label: "Título",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "recurso_nome",
            label: "Respons.",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "data_fim_prevista",
            label: "Data",
            variant: row => handleColumnVariant(row),
            format: value => (value && moment(value).format("DD/MM/YYYY")) || '---'
         }
      ]
   }

   function openPopover(event, rows = [], columns = [], title = "") {
      setAnchorEl(event.target);
      setRows(rows);
      setColumns(columns);
      setPopoverTitle(title);
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"QA"}>
            <div style={{ flex: 1 }}>
               <div onClick={event => openPopover(event, tarefas.lista_aprs, allColumns.tarefas, 'APRs')} className={classes.clickable}>
                  <Typography variant="h4" color="textPrimary" align="center"> {indicadores.aprs} </Typography>
                  <Typography style={{ fontSize: "14px" }} color="textPrimary" align="center"> APRs </Typography>
               </div>
               <div onClick={event => openPopover(event, tarefas.lista_codereview, allColumns.tarefas, 'Code Review')} className={classes.clickable}>
                  <Typography variant="h4" color="textPrimary" align="center"> {indicadores.codereview} </Typography>
                  <Typography style={{ fontSize: "14px" }} color="textPrimary" align="center"> CODE REVIEW </Typography>
               </div>
            </div>
         </DashCard>
         <DashPopover anchor={anchorEl} onClose={_ => setAnchorEl(null)}  title={popoverTitle}>
            <DashTable columns={columns} rows={rows} emptyMessage="Nenhuma tarefa disponível" />
         </DashPopover>
      </React.Fragment>
   )
});

export default OutrosCard;