import React, { useState } from 'react';
import moment from "moment";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { DashCard, DashPopover, DashTable } from "../components/components";

const styles = {
   value: {
      fontSize: '4.8rem',
      color: '#444444',
      marginBottom: '10px'
   },
   clickable: {
      cursor: "pointer",
		"&:hover": {
			opacity: 0.5
      },
      marginTop: '7px',
      marginBottom: '7px'
   }
}

export const EvolucaoCard = withStyles(styles)((props) => {

   const [anchorEl, setAnchorEl] = useState(null);
   const [rows, setRows] = useState([]);
   const [columns, setColumns] = useState([]);
   const [popoverTitle, setPopoverTitle] = useState("");

   const id = (props && props.id) || "evolucao";

   const indicadores = (props && props.tarefas.indicadores) || { indicadores: {implantacao: 0, fasttrack: 0, evolucao: 0, debitostecnicos: 0}};
   const tarefas = (props && props.tarefas) ||  [];

   const classes = (props && props.classes) || {};

   const allColumns = {
      tarefas: [
         {
            identifier: "tarefa_id",
            label: "ID",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "cliente",
            label: "Cliente",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "tarefa_titulo",
            label: "Título",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "tipo_atendimento",
            label: "Tipo",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "recurso_nome",
            label: "Respons.",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "sla",
            label: "SLA",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "pf",
            label: "PF",
            variant: row => handleColumnVariant(row)
         },
         {
            identifier: "data_fim_prevista",
            label: "Data Prevista",
            variant: row => handleColumnVariant(row),
            format: value => (value && moment(value).format("DD/MM/YYYY")) || '---'
         }
      ]
   }

   function openPopover(event, rows = [], columns = [], title = "") {
      setAnchorEl(event.target);
      setRows(rows);
      setColumns(columns);
      setPopoverTitle(title);
   }

   function handleColumnVariant(row) {
      if (row.executando) {
         return "info";
      }else if (row.finalizado || row.tipo === 'Saída') {
         return "success";
      }else if (row.atrasado || row.sla_vencido === 1 || row.tipo === 'Entrada' || row.severidade === 'Emergência') {
         return "danger";
      }else if (row.sla_vencido === 2 || row.severidade === 'Severidade 1'){  // à vencer
         return "warning";
      }else{
         return "default";
      }
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"DEMAIS TRACKS"} >
            <div style={{ flex: 1 }}>
               <div onClick={event => openPopover(event, tarefas.lista_implantacao, allColumns.tarefas, 'Implantação')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
                  <Typography style={{ fontSize: "12px" }} color="textPrimary" align="center"> IMPLANTAÇÃO </Typography>
                  <Typography variant="h5" color="textPrimary" align="center"> {indicadores.implantacao} </Typography>
               </div>
               <div onClick={event => openPopover(event, tarefas.lista_fasttrack, allColumns.tarefas, 'Fast-Track')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
                  <Typography style={{ fontSize: "12px" }} color="textPrimary" align="center"> FAST-TRACK </Typography>
                  <Typography variant="h5" color="textPrimary" align="center"> {indicadores.fasttrack} </Typography>
               </div>
               <div onClick={event => openPopover(event, tarefas.lista_evolucao, allColumns.tarefas, 'Evolução')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
                  <Typography style={{ fontSize: "12px" }} color="textPrimary" align="center"> EVOLUÇÃO </Typography>
                  <Typography variant="h5" color="textPrimary" align="center"> {indicadores.evolucao} </Typography>
               </div>
               <div onClick={event => openPopover(event, tarefas.lista_debitostecnicos, allColumns.tarefas, 'Débitos tecnicos')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
                  <Typography style={{ fontSize: "12px" }} color="textPrimary" align="center"> DÉBITOS TÉCNICOS </Typography>
                  <Typography variant="h5" color="textPrimary" align="center"> {indicadores.debitostecnicos} </Typography>
               </div>
            </div>
         </DashCard>
         <DashPopover anchor={anchorEl} onClose={_ => setAnchorEl(null)} title={popoverTitle}>
            <DashTable columns={columns} rows={rows} emptyMessage="Nenhuma tarefa disponível" />
         </DashPopover>
      </React.Fragment>
   )
});

export default EvolucaoCard;