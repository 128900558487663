import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";
import { DashCard, DashPopover, DashTable } from "../components/components";

const styles = {
   value: {
      fontSize: '5.5rem',
      color: '#444444',
      marginBottom: '10px'
   },
   valueDetail: {
      fontSize: '0.7rem',
      color: '#444444',
      fontWeight: 'bold',
   },
   clickable: {
      cursor: "pointer",
		"&:hover": {
			opacity: 0.5
		}
   }
}

export const SLASegmentadosCard = withStyles(styles)((props) => {

   const [anchorEl, setAnchorEl] = useState(null);
   const [rows, setRows] = useState([]);
   const [columns, setColumns] = useState([]);
   const [popoverTitle, setPopoverTitle] = useState("");

   const id = (props && props.id) || "sla";
   const sla = (props && props.sla) || {geral: 0, segmentados: 0};
   const dias = (props && props.dias) || [];

   const classes = (props && props.classes) || {};

   const chart = {
      options: {
         chart: {
            id: "slachart",
            toolbar: { show: false },
            animations: { enabled: false },
            offsetY: -10
         },
         plotOptions: {
            radialBar: {
               startAngle: 0,
               endAngle: 270,
               hollow: {
                  margin: 5,
                  size: '20%',
                  background: 'transparent',
                  image: undefined,
               },
               dataLabels: {
                  name: {
                     show: true,
                  },
                  value: {
                     show: true,
                  }
               }
            }
         },
         colors: ['#4472C4', '#70AD47'],
         labels: ['GERAL', 'SEGM. '],
         legend: {
            show: true,
            floating: true,
            fontSize: '15px',
            position: 'left',
            offsetX: -10,
            offsetY: 50,
            labels: {
               useSeriesColors: true,
            },
            markers: {
               width: 0,
               height: 0,
            },
            formatter: function (seriesName, opts) {
               return "<b>" + seriesName + ":</b>  " + Math.round(opts.w.globals.series[opts.seriesIndex]) + '%'
            },
            itemMargin: {
               horizontal: 3,
            }
         },
         responsive: [{
            breakpoint: 480,
            options: {
               legend: {
                  show: false
               }
            }
         }],
      },

      series: [sla.geral, sla.segmentados]
   }

   const allColumns = {
      dias: [
         {
            identifier: "data",
            label: "Data"
         },
         {
            identifier: "qtde",
            label: "Qtde"
         }
      ]
   }

   function openPopover(event, rows = [], columns = [], title = "") {
      setAnchorEl(event.target);
      setRows(rows);
      setColumns(columns);
      setPopoverTitle(title);
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"SLA  - Últimos 30 dias"}>
            <div onClick={event => openPopover(event, dias, allColumns.dias, 'Próximos dias')} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={classes.clickable}>
               <Chart
                  options={chart.options}
                  series={chart.series}
                  type="radialBar"
                  height={350}
               />
            </div>
         </DashCard>
         <DashPopover anchor={anchorEl} onClose={_ => setAnchorEl(null)} title={popoverTitle}>
            <DashTable columns={columns} rows={rows} emptyMessage="Não disponível" />
         </DashPopover>
      </React.Fragment>
   )
});


export default SLASegmentadosCard;