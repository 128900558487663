import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { DashCard, DashTable } from "../components/components";

const styles = {
   TableCell: {
      backgroundColor: "#FFFFFF",
		fontSize: '2.5rem',
   }
}

export const EmergenciasCard = withStyles(styles)((props) => {

   const id = (props && props.id) || "emergencias";
   const emergencias = (props && props.emergencias) || [];
   //const classes = (props && props.classes) || {};

   const columns = [
      {
         identifier: "cliente",
         label: "",
      },
      {
         identifier: "descricao",
         label: "",
      },
      {
         identifier: "responsavel",
         label: ""
      },
      {
         identifier: "aberto_a",
         label: "",
         mobile: false
      }
   ];

   function contEmergAtribuidas() {
      let count = 0;
      if (emergencias.length > 0) {
         emergencias.map((item) => count += (item.responsavel !== '') ? 1 : 0);
      }
      return count;
   }

   return (
      <React.Fragment>
         <DashCard id={id} title={"EMERGÊNCIAS"} details={`${contEmergAtribuidas()}/${emergencias.length}`} variant={emergencias.length > 0 ? 'danger' : 'primary'}>
            <DashTable columns={columns} rows={emergencias} tableCellBig={true} emptyMessage="Nenhuma emergência em andamento" />
         </DashCard>
      </React.Fragment>
   )
});

export default EmergenciasCard;