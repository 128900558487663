import React from "react";
import { Card, CardContent, Typography, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { red, blue } from '@material-ui/core/colors';

const styles = {
   Card: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      margin: "4px"
   },
   CardHeader: {
      backgroundColor: blue[700],
      padding: "16px",
      display: "flex",
      alignItems: "center",
      position: "relative"
   },
   headerBlue: {
      backgroundColor: blue[700],
   },
   headerRed: {
      backgroundColor: red[700],
   },
   iconHeader: {
      padding: "9px"
   },
   CardTitle: {
      color: '#FFFFFF',
		fontSize: '0.8rem',
   },
   CardSubTitle: {
      color: '#FFFFFF',
      fontSize: '2rem',
      fontWeight: 'bold',
      position: "absolute",
      right: "8px"
   },
   CardContent: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      paddingTop: "8px !important",
      paddingBottom: "8px !important"
   },
   clickable: {
      cursor: "pointer",
		"&:hover": {
			opacity: 0.5
		}
   },
   IconButton: {
      fill: "white",
      color: "white",
      padding: "4px"
   }
}

export const DashCard = withStyles(styles)((props) => {

   const id = (props && props.id) || "card";
   const children = (props && props.children) || null;
   const classes = (props && props.classes) || {};
   const onClick = (props && props.onClick) || null;
   const title = (props && props.title) || "";
   const details = (props && props.details) || null;
   const variant = (props && props.variant) || "primary";
   const icon = (props && props.icon) || null;
   const iconClick = (props && props.iconClick) || null;

   return (
      <Card id={id} onClick={onClick} className={`${classes.Card} ${(onClick && classes.clickable) || ""}`}>
         <div className={`${classes.CardHeader} ${variant === 'danger' ? classes.headerRed : classes.headerBlue} ${icon ? classes.iconHeader:''}`}>
            {icon && <IconButton className={classes.IconButton} onClick={iconClick}>{icon}</IconButton>}
            <Typography className={classes.CardTitle}>{title}</Typography>
            {details && <Typography className={classes.CardSubTitle}>{details}</Typography>}
         </div>
         <CardContent className={classes.CardContent}>
            {children}
         </CardContent>
      </Card>
   )
});

export default DashCard;
