import "./dashboard.css";
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from "notistack";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import { Button, Toolbar, Typography, Menu, MenuItem } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import KmmService from "../kmm-service/src/";
import { Fetch } from "../storage";
import { Animation } from "./components/components";
import Notification from 'react-web-notification';

import {
	TarefasCard,
	SustentacaoCard,
	EvolucaoCard,
	OutrosCard,
	TipoDemandaCard,
	BurndownCard,
	HistoricoEstoqueCard,
	HistoricoEstoqueTracksCard,
	SLASegmentadosCard,
	EmergenciasExecutadasCard,
	EmergenciasCard,
	RecursosCard,
	RecorrenciaCard
} from "./cards/cards";

const styles = {
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	appBar: {
		zIndex: 9
	},
	toolbar: {
		paddingRight: 24,
		backgroundColor: '#777777',
	},
	title: {
		flexGrow: 1,
		fontSize: '1rem',
		cursor: 'pointer',
	}
};

class Dashboard extends Component {

	state = {
		equipes: [],
		equipeAnchor: null,
		equipe: Fetch.userTeam(),
		tarefas: { indicadores: {}, pendentes: [], estoque: [], estoque_at3: [], atrasadas: [] },
		estoque_detalhe: { emergencias_suporte: [], previsao: [], pendentes: [], analise: [], teste: [], razao: [], razao_temp: [] },
		projetos: { fila: [], execucao: [], homologacao_interna: [], homologacao_externa: [] },
		burndown: [],
		filas: [],
		sla: { geral: 0 },
		recorrencia: { geral: 0 },
		slaSegmentados: {sla: {geral: 0, segmentados: 0}, dias: []},
		tipodemanda: { indicadores: [], evolucao: [], retrabalho: [], suporte: [], organizacao: [] },
		emergencias: [],
		desempenho: 0,
		emergenciasExecutadas: [],
		recursos: [],
		tipoDemandaTarefas: [],
		desempenhoRecursos: [],
		tarefasRecursos: [],
		isRecursosOpen: false,
		animationRunning: false,
		animationType: 'danger',
		animationText: '',
		notification: { title: "", text: "" },
		notificationsShowed: [],
		timeOuts: [],
		animationEnabled: true,
		sincronizado: false,
		carouselActive: 0,
	}

	animation = null;
	animationTimeout = null;

	componentDidMount() {
		this.getTeams();
		this.sincronizarChamadas();

		document.addEventListener("keydown", event => {
			if (event.ctrlKey && event.altKey && event.keyCode === 83) {
				this.setAnimation('success', 'BBM  - 000001 Reprocessamento de Pneus')
			}
			if (event.ctrlKey && event.altKey && event.keyCode === 68) {
				this.setAnimation('danger', 'BBM  - 000001 Reprocessamento de Pneus')
			}
			if (event.ctrlKey && event.altKey && event.keyCode === 70) {
				this.setAnimation('bad', 'ENTRADA: 9 SAÍDA: 10\nRAZÃO: +1', 5000);
			}
			if (event.ctrlKey && event.altKey && event.keyCode === 71) {
				this.setAnimation('good', 'ENTRADA: 9 SAÍDA: 10\nRAZÃO: -1', 5000);
			}
			if (event.ctrlKey && event.altKey && event.keyCode === 72) {
				this.newNotification(1, 'Emergência', '000001 CTe não integrando');
			}
			if (event.ctrlKey && event.altKey && event.keyCode === 73) {
				this.newNotification(2, 'Emergência', '000002 MDFe não integrando');
			}
			if (event.ctrlKey && event.altKey && event.keyCode === 65) {
				this.setState({animationEnabled : !this.state.animationEnabled});
				this.newNotification(10, 'Dashboard Produção', 'Animações '+ (this.state.animationEnabled ? 'ativadas.' : 'desativadas.'), true);
			}


		})

	}

	componentDidUpdate(prevProps) {
		if (prevProps.equipe !== this.props.equipe) {
			this.getData();
		}
	}

	isAuth = () => {
		return Fetch.token();
	};

	getTeams = () => {
		KmmService("M1504", "getEquipes", {}, response => {
			if (response.success) {
				this.setState({ equipes: response.result.equipes });
				
				this.getData();
				this.carousel();
			}
		});
	}

	carousel = () => {
		console.log('carousel', this.props.carousel);
		if(this.props.carousel === 'on'){
			this.setState({animationEnabled : false});
			
			if(this.state.equipe.equipe_id !== this.state.equipes[this.state.carouselActive].equipe_id){
				console.log('carousel change team', this.state.equipes[this.state.carouselActive]);
				this.selectTeam(this.state.equipes[this.state.carouselActive]);
			}

			let running = this.state.carouselActive;
			this.setState({carouselActive : (running === this.state.equipes.length-1 ? 0 : running + 1)});
			setTimeout(() => this.carousel(), 30000);

		}
	}

	destroyAnimation = () => {
		this.setState({ animationRunning: false });
	}

	setAnimation = (type = 'danger', text = '', timeout = 6000) => {
		if(this.state.animationEnabled){
			if (this.state.animationRunning === true) {
				setTimeout(() => this.setAnimation(type, text, timeout), 5000);
			} else {
				this.setState({
					animationType: type,
					animationText: text,
					animationRunning: true,
				});
				this.animationTimeout = setTimeout(() => this.destroyAnimation(), timeout);
			}
		}
	}

	handlePermissionGranted() {
		this.setState({
			notification: { ignore: false }
		});
	}
	handlePermissionDenied() {
		this.setState({
			notification: { ignore: true }
		});
	}
	handleNotSupported() {
		this.setState({
			notification: { ignore: true }
		});
	}

	newNotification = (id, title, text, repeatable = false) => {
		if (this.state.notification.ignore) {
			return;
		}

		if (!this.state.notificationsShowed.some(item => id === item)) {
			this.setState({
				notification: {
					title: title,
					options: {
						tag: id,
						body: text,
						icon: 'android-chrome-192x192.png',
					},
					ignore: false,
				}
			});

			if(!repeatable)
				this.state.notificationsShowed.push(id);
		}

	}

	clearData = () => {
		this.clearTimeOuts();
		this.setState({
			tarefas: { 
				indicadores: { 
					pendentes: 0, atrasadas: 0, estoque: 0, corretivas: 0, outros: 0, estoque_at3: 0, sustentacao: 0, outras_tracks: 0, implantacao: 0, fasttrack: 0, evolucao: 0, debitostecnicos: 0, aprs: 0, codereview: 0
				}, 
				pendentes: [], 
				estoque: [], 
				estoque_at3: [], 
				atrasadas: [] 
			},
			estoque_detalhe: { emergencias_suporte: [], previsao: [], pendentes: [], analise: [], teste: [], razao: [] },
			projetos: { fila: [], execucao: [], homologacao_interna: [], homologacao_externa: [] },
			burndown: [{ hora: '08:00', qtde: 0, origem_sla: 0, indice: 0, ideal: 0, dentro_ideal: 0 }],
			filas: [{ data: '01/01', geral: 0 }],
			sla: { geral: 0 },
			slaSegmentados: {geral: 0, segmentados: 0, dias: []},
			recorrencia: { recorrencia: 0 },
			tipodemanda: { indicadores: [{ tipo: 'Evolução', tempo: 0, tempo_formatado: '0:00' }], evolucao: [], retrabalho: [], suporte: [], organizacao: [] },
			emergencias: [],
			desempenho: 0,
			emergenciasExecutadas: { emergencia : {tempo: '0:00', qtde: 0 }, emergencias_lista: []},
			recursos: [],
			timeOuts: [],
			tipoDemandaTarefas: [],
			desempenhoRecursos: [],
			tarefasRecursos: []
		});
	}

	getData = () => {
		this.clearData();
		if (this.state.equipe && this.state.equipe.equipe_id) {
			this.getTarefas();
			this.getAtendimentos();
			this.getProjetos();
			this.getBurnDown();
			this.getFilas();
			this.getSLA();
			this.getRecorrencia();
			this.getTipoDemanda();
			this.getEmergencias();
			this.getDesempenho();
			this.getHrsEmergencia();
			this.getRecursos();
		} else { 
			//this.clearData();
			/*this.props.enqueueSnackbar('Equipe informada inválida', {
				variant: "error"
			});*/
			this.selectTeam(this.state.equipes[0]);
		}
	}

	sincronizarChamadas = () => {
		let date = new Date();
		let minutes = [0,10,20,30,40,50];

		if(this.state.sincronizado === false){
			if (minutes.indexOf(date.getMinutes()) > -1) {
				//console.log('Hora de sincronismo '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds());
				this.getData();
				this.setState({sincronizado: true});
				this.props.enqueueSnackbar("Sincronizado", {
					variant: "success"
			  	});
			}

			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.sincronizarChamadas, 1000));
			}
		}

	}

	getTarefas = () => {
		let nextCall = 30000;
		KmmService("M1504", "getTarefas", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				if (response.result.indicadores)
					this.setState({
						tarefas: response.result
					});
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getTarefas, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getAtendimentos = () => {
		let nextCall = 31000;
		KmmService("M1504", "getAtendimentos", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				if (response.result) {
					this.setState({
						estoque_detalhe: response.result
					});
					var date = new Date();
					if (date.getHours() === 18 && date.getMinutes() === 0 && date.getSeconds() <= 30) {
						this.resultadoAtual();
					}
				}
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getAtendimentos, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	resultadoAtual = () => {
		if (this.estoque_detalhe.razao.razao <= 0)
			this.setAnimation('good', 'ENTRADA: ' + this.estoque_detalhe.razao.entrantes + ' SAÍDA: ' + this.estoque_detalhe.razao.saintes + '\nRAZÃO: ' + this.estoque_detalhe.razao.razao, 60000);
		else
			this.setAnimation('bad', 'ENTRADA: ' + this.estoque_detalhe.razao.entrantes + ' SAÍDA: ' + this.estoque_detalhe.razao.saintes + '\nRAZÃO: +' + this.estoque_detalhe.razao.razao, 60000);
	}

	getProjetos = () => {
		let nextCall = 3600000;
		KmmService("M1504", "getProjetos", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				if (response.result)
					this.setState({
						projetos: response.result
					});
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getProjetos, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getBurnDown = () => {
		let nextCall = 62000;
		KmmService("M1504", "getBurnDown", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				this.setState({ burndown: response.result.horas });
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getBurnDown, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getFilas = () => {
		let nextCall = 123000;
		KmmService("M1504", "getFilas", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				this.setState({ filas: response.result.filas });
			} else {
				if (this.isAuth()) {
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getFilas, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getSLA = () => {
		let nextCall = 3604000;
		KmmService("M1504", "getSLA", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				this.setState({ slaSegmentados: response.result });
			} else {
				if (this.isAuth()) {
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getSLA, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getRecorrencia = () => {
		let nextCall = 3615000;
		KmmService("M1504", "getRecorrencia", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
					this.setState({ recorrencia: response.result.recorrencia });
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getRecorrencia, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getTipoDemanda = () => {
		let nextCall = 66000;
		KmmService("M1504", "getTipoDemanda", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				if (response.result.indicadores.length > 0)
					this.setState({
						tipodemanda: response.result
					});
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getTipoDemanda, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getEmergencias = () => {
		let nextCall = 60000;
		KmmService("M1504", "getEmergencias", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				this.setState(prevState => {
					const emergencias = response.result.emergencias;
					let naoAtribuidas = this.emergenciasNaoAtribuidas(emergencias);

					if (naoAtribuidas.length > 0) {
						this.setAnimation('danger', this.getTextEmergencias(naoAtribuidas));

					} else if (this.countEmergenciasAtribuidas(prevState.emergencias) > emergencias.length) {

						let saintes = this.emergenciasSaintes(prevState.emergencias, emergencias);
						this.setAnimation('success', this.getTextEmergencias(saintes));
					}
					
					if (emergencias.length > 0) {
						emergencias.forEach((emergencia) => {
							if (this.state.equipe.recurso === emergencia.recurso)
								this.newNotification(emergencia.protocolo, 'Emergência', emergencia.protocolo + ' ' + emergencia.titulo);
						});
					}

					return { emergencias: emergencias };
				});
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getEmergencias, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getDesempenho = () => {
		let nextCall = 67000;
		KmmService("M1504", "getDesempenho", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				if (response.result.desempenho) {
					this.setState({
						desempenho: response.result.desempenho.percentual
					});
				}
				if (response.result.recursos) {
					this.setState({
						desempenhoRecursos: response.result.recursos
					});
				}
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getDesempenho, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getHrsEmergencia = () => {
		let nextCall = 68000;
		KmmService("M1504", "getHrsEmergencia", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				this.setState({
					emergenciasExecutadas: response.result
				});
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getHrsEmergencia, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getRecursos = () => {
		let nextCall = 69000;
		KmmService("M1504", "getRecursos", { "equipe_id": this.state.equipe.equipe_id }, response => {
			if (response.success) {
				this.setState({
					recursos: response.result.recursos
				});
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getRecursos, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	getRecursoTarefas = (recurso_id) => {
		let nextCall = 68000;
		KmmService("M1504", "getRecursoTarefas", { "recurso_id": recurso_id }, response => {
			if (response.success) {
				this.setState({
					tarefasRecursos: response.result.tarefas
				});
			} else {
				if (this.isAuth()) {
					/*this.props.enqueueSnackbar(response.message, {
						variant: "error"
					});*/
					nextCall = 5000;
				}
			}
			if (this.isAuth()) {
				this.state.timeOuts.push(setTimeout(this.getRecursos, nextCall));
			}
			else {
				this.props.onLogout();
			}
		});
	}

	countEmergenciasAtribuidas = (emergs) => {
		let count = 0;
		if (emergs.length > 0) {
			emergs.map((item) => count += (item.responsavel !== '') ? 1 : 0);
		}
		return count;
	}

	emergenciasNaoAtribuidas = (emergencias) => {
		let naoAtribuidas = [];
		if (emergencias.length > 0) {
			emergencias.map((item) => item.responsavel === '' ? naoAtribuidas.push(item) : null);
		}
		return naoAtribuidas;
	}

	emergenciasSaintes = (before, now) => {
		let saintes = [...before];
		if (before.length > 0) {
			before.forEach(function (itemBefore) {
				if(now.length > 0){
					now.forEach(function (itemNow) {
						if(itemBefore.protocolo === itemNow.protocolo && itemBefore.responsavel !== ''){
							saintes.splice(saintes.indexOf(itemBefore), 1);
						}
					});
				}
			});
		}
		return saintes;

	}

	getTextEmergencias = (emergencias) => {
		let text = [];
		emergencias.forEach(function (emergencia) {
			text += emergencia.cliente+' - '+emergencia.descricao + "\n";
		});

		return text;
	}

	toggleEquipe = () => {
		this.state.timeOuts.forEach(function (timeOut) {
			clearTimeout(timeOut);
		});
		this.setState({
			timeOuts: []
		});
		//this.setState({ equipe: (this.state.equipe === 'erp' ? 'tms' : 'erp') }, this.getData);
	}

	handleClick = (event) => {
		this.setState({ equipeAnchor: event.currentTarget })
	}

	handleClose = () => {
		this.setState({ equipeAnchor: null });
	}

	selectTeam = (team) => {
		this.clearTimeOuts();
		this.setState({ equipe: team, equipeAnchor: null, timeOuts: [] }, this.getData);
	}

	clearTimeOuts = () => {
		if(this.state.timeOuts){
			this.state.timeOuts.forEach(function (timeOut) {
				clearTimeout(timeOut);
			});
		}
	}

	render() {

		const { classes } = this.props;
		const { emergencias, emergenciasExecutadas, tarefas, estoque_detalhe, burndown, recorrencia, slaSegmentados, tipodemanda, desempenho, recursos, filas, animationRunning, animationType, animationText } = this.state;
		return (
			<main className={classes.content}>
				{animationRunning === true &&
					<Animation type={animationType} text={animationText} />
				}
				<div className="dash-container">
					<div id="dashboard" className='dashboard'>
						<CssBaseline />
						<AppBar position="absolute" className={classes.appBar}>
							<Toolbar className={classes.toolbar}>
								<Menu
									anchorEl={this.state.equipeAnchor}
									keepMounted
									open={Boolean(this.state.equipeAnchor)}
									onClose={this.handleClose}
								>
									{this.state.equipes && this.state.equipes.map(equipe => <MenuItem key={equipe.nome} onClick={() => this.selectTeam(equipe)}>{equipe.nome}</MenuItem>)}
								</Menu>

								<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} onClick={this.handleClick}>
									Dashboard - Equipe {(this.state.equipe && this.state.equipe.nome && this.state.equipe.nome) || 'não definida'}
								</Typography>
								<Button variant="contained" color="primary" onClick={this.props.onLogout}><ExitToApp /></Button>
							</Toolbar>
						</AppBar>

						<TarefasCard id="ind_tarefas_pendentes" indicadores={tarefas.indicadores} tarefas={{ atrasadas: tarefas.atrasadas, pendentes: tarefas.pendentes }} />
						
						<SustentacaoCard id="ind_sustentacao" 
							tarefas={tarefas} 
							estoque_detalhe={estoque_detalhe} />

						<EvolucaoCard id="ind_evolucao" tarefas={tarefas} />

						<OutrosCard id="ind_outros" tarefas={tarefas} />

						
						<RecorrenciaCard id="recorrencia" recorrencia={recorrencia} />

						
						<TipoDemandaCard id="tipo_demanda" indicadores={tipodemanda.indicadores} tipodemanda={{ evolucao: tipodemanda.evolucao, retrabalho: tipodemanda.retrabalho, suporte: tipodemanda.suporte, organizacao: tipodemanda.organizacao }} />
						<BurndownCard id="burndown" burndown={burndown} />

						{this.state.equipe.equipe_id === -99 ? (
							<HistoricoEstoqueCard id="historico_estoque" filas={filas} />
						) : (
								<HistoricoEstoqueTracksCard id="historico_estoque" filas={filas} />
							)}

						<SLASegmentadosCard id="sla" sla={slaSegmentados.sla} dias={slaSegmentados.dias} />
						
						<EmergenciasExecutadasCard id="emergencias_executadas" indicadores={emergenciasExecutadas} />
						<EmergenciasCard id="emergencias" emergencias={emergencias} />

					</div>
					<RecursosCard id="recursos" desempenho={`${Math.round(desempenho)}%`} recursos={recursos}></RecursosCard>
					{this.state.notification.title !== '' ? (
						<Notification
							ignore={this.state.notification.ignore && this.state.notification.title !== ''}
							askAgain={true}
							notSupported={this.handleNotSupported.bind(this)}
							onPermissionGranted={this.handlePermissionGranted.bind(this)}
							onPermissionDenied={this.handlePermissionDenied.bind(this)}
							timeout={10000}
							title={this.state.notification.title ? this.state.notification.title : 'Dashboard Produção'}
							options={this.state.notification.options}
						/>
					) : ''}
				</div>
			</main>
		);
	}
}

export default withStyles(styles)(withSnackbar(Dashboard));